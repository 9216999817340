define('ui/components/modal-rotate-certificates/component', ['exports', 'shared/mixins/modal-base', 'ui/components/modal-rotate-certificates/template'], function (exports, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, {
    growl: Ember.inject.service(),

    layout: _template.default,
    classNames: ['large-modal'],
    rotateCaCerts: false,
    services: null,
    selectedServices: null,
    mode: 'single',

    init: function init() {
      this._super.apply(this, arguments);

      Ember.setProperties(this, {
        services: [],
        errors: []
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'services', this.modalOpts.serviceDefaults);
    },


    actions: {
      rotateCaCerts: function rotateCaCerts(cb) {
        var _this = this;

        var resource = this.modalOpts.model;
        var params = this.getRotateCertsParams();

        resource.doAction('rotateCertificates', params).then(function () {
          _this.send('cancel');
        }).catch(function (err) {
          _this.growl.fromError(err);
          if (cb) {
            cb(false);
          }
        });
      },
      mutServices: function mutServices(select) {
        Ember.set(this, 'selectedServices', select);
      }
    },

    getRotateCertsParams: function getRotateCertsParams() {
      switch (this.mode) {
        case 'caAndService':
          return {
            services: '',
            caCertificates: true
          };
        case 'single':
          return {
            services: Ember.get(this, 'selectedServices'),
            caCertificates: false
          };
        case 'service':
          return {
            services: null,
            caCertificates: false
          };
        default:
          return;
      }
    }
  });
});