define('ui/models/nodetemplate', ['exports', '@rancher/ember-api-store/models/resource', 'shared/utils/util', 'shared/mixins/node-driver'], function (exports, _resource, _util, _nodeDriver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    intl: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),

    type: 'nodeTemplate',
    canClone: true,

    config: Ember.computed('driver', function () {
      var driver = Ember.get(this, 'driver');

      return Ember.get(this, driver + 'Config');
    }),

    displayProvider: Ember.computed('driver', 'intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var driver = Ember.get(this, 'driver');
      var key = 'nodeDriver.displayName.' + driver;

      if (intl.exists(key)) {
        return intl.t(key);
      } else {
        return (0, _util.ucFirst)(driver);
      }
    }),

    displaySize: Ember.computed('config', function () {
      var driver = Ember.get(this, 'driver');

      return this._displayVar((0, _nodeDriver.getDisplaySize)(driver) || 'config.size');
    }).volatile(),

    displayLocation: Ember.computed(function () {
      var driver = Ember.get(this, 'driver');

      return this._displayVar((0, _nodeDriver.getDisplayLocation)(driver) || 'config.region');
    }).volatile(),

    actions: {
      edit: function edit() {
        var driver = Ember.get(this, 'driver');

        Ember.get(this, 'modalService').toggleModal('modal-edit-node-template', {
          driver: driver,
          config: Ember.get(this, driver + 'Config'),
          nodeTemplate: this,
          edit: true
        });
      },
      clone: function clone() {
        var driver = this.driver;


        Ember.get(this, 'modalService').toggleModal('modal-edit-node-template', {
          driver: driver,
          config: Ember.get(this, driver + 'Config'),
          nodeTemplate: this,
          clone: true
        });
      }
    },

    _displayVar: function _displayVar(keyOrFn) {
      var intl = Ember.get(this, 'intl');

      if (keyOrFn) {
        if (typeof keyOrFn === 'function') {
          return keyOrFn.call(this);
        } else {
          return Ember.get(this, keyOrFn) || intl.t('generic.unknown');
        }
      } else {
        return intl.t('generic.unknown');
      }
    },
    clearConfigsExcept: function clearConfigsExcept(keep) {
      var keys = this.allKeys().filter(function (x) {
        return x.endsWith('Config');
      });

      for (var key, i = 0; i < keys.length; i++) {
        key = keys[i];
        if (key !== keep && Ember.get(this, key)) {
          Ember.set(this, key, null);
        }
      }
    }
  });
});