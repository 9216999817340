define('ui/models/multiclusterapp', ['exports', '@rancher/ember-api-store/models/resource', '@rancher/ember-api-store/utils/denormalize', 'ui/utils/parse-externalid'], function (exports, _resource, _denormalize, _parseExternalid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MultiClusterApp = _resource.default.extend({
    catalog: Ember.inject.service(),
    router: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    globalStore: Ember.inject.service(),

    canEdit: false,
    canClone: true,

    templateVersion: (0, _denormalize.reference)('templateVersionId', 'templateVersion', 'globalStore'),
    catalogTemplate: (0, _denormalize.reference)('externalIdInfo.templateId', 'template', 'globalStore'),

    externalIdInfo: Ember.computed('templateVersion.externalId', function () {
      return (0, _parseExternalid.parseHelmExternalId)(Ember.get(this, 'templateVersion.externalId'));
    }),

    availableActions: Ember.computed('actionLinks.{rollback}', 'links.{update}', function () {
      var a = Ember.get(this, 'actionLinks') || {};
      var l = Ember.get(this, 'links') || {};

      var choices = [{
        label: 'action.upgrade',
        icon: 'icon icon-edit',
        action: 'upgrade',
        enabled: !!l.update
      }, {
        label: 'action.rollback',
        icon: 'icon icon-history',
        action: 'rollback',
        enabled: !!a.rollback
      }];

      return choices;
    }),

    actions: {
      upgrade: function upgrade() {
        var templateId = Ember.get(this, 'externalIdInfo.templateId');
        var catalogId = Ember.get(this, 'externalIdInfo.catalog');
        var vKeys = Object.keys(Ember.get(this, 'catalogTemplate.versionLinks'));
        var latestVersion = vKeys[vKeys.length - 1];

        Ember.get(this, 'router').transitionTo('global-admin.multi-cluster-apps.catalog.launch', templateId, {
          queryParams: {
            appId: Ember.get(this, 'id'),
            catalog: catalogId,
            upgrade: latestVersion
          }
        });
      },
      rollback: function rollback() {
        Ember.get(this, 'modalService').toggleModal('modal-rollback-mc-app', {
          originalModel: this,
          revisionsLink: this.links.revisions
        });
      },
      clone: function clone() {
        var templateId = Ember.get(this, 'externalIdInfo.templateId');
        var catalogId = Ember.get(this, 'externalIdInfo.catalog');

        Ember.get(this, 'router').transitionTo('global-admin.multi-cluster-apps.catalog.launch', templateId, {
          queryParams: {
            appId: Ember.get(this, 'id'),
            catalog: catalogId,
            clone: true
          }
        });
      }
    }

  });

  exports.default = MultiClusterApp;
});